exports.getenv = function (property, isProd, platform = 'nikshay'){
  var platformENV = platform + "-" + (isProd ? 'prod' : 'beta')
  var registryENV = 'Registry/' + platformENV
  var adminENV = 'Admin/' + platformENV
  switch (property){
    case "platformENV":
      return platformENV
    case "registryENV":
      return registryENV
    case "adminENV":
      return adminENV
    default:
      return ""
  }
}
exports.getRegisteryClientId = function (env){
  switch (env){
    case "Registry/nikshay-beta":
      return process.env.VUE_APP_REGISTRY_NIKSHAY_BETA_CLIENT_ID
    case "Registry/nikshay-prod":
      return process.env.VUE_APP_REGISTRY_NIKSHAY_PROD_CLIENT_ID
    case "Registry/Hub-beta":
      return process.env.VUE_APP_REGISTRY_PLATFORM_BETA_CLIENT_ID
    case "Registry/Hub-prod":
      return process.env.VUE_APP_REGISTRY_PLATFORM_PROD_CLIENT_ID
    case "Registry/Ascent-prod":
      return process.env.VUE_APP_REGISTRY_ASCENT_PROD_CLIENT_ID
    case "Registry/Tanzania-prod":
      return process.env.VUE_APP_REGISTRY_TANZANIA_PROD_CLIENT_ID
    default:
      return ''
  }
}

exports.getAdminClientId = function (env){
  switch (env){
    case "Admin/nikshay-beta":
      return process.env.VUE_APP_ADMIN_NIKSHAY_BETA_CLIENT_ID
    case "Admin/nikshay-prod":
      return process.env.VUE_APP_ADMIN_NIKSHAY_PROD_CLIENT_ID
    case "Admin/Hub-beta":
      return process.env.VUE_APP_ADMIN_PLATFORM_BETA_CLIENT_ID
    case "Admin/Hub-prod":
      return process.env.VUE_APP_ADMIN_PLATFORM_PROD_CLIENT_ID
    default:
      return ''
  }
}
