import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'
import { ApiClient } from './api'
import { AdminApiClient } from './adminapi'
import { getUsernameAndPassword, apiResponseType, apiResponseValue } from '../../utils/dataValidations'
import { DataForMatomoTracking, adminLogData } from '../../utils/matomoTracking'
import Toasted from 'vue-toasted'
Vue.use(Toasted)

Vue.use(Vuex)
const vuexCookie = new VuexPersistence({
  reducer: (state) => ({ isAuth: state.isAuth, emailId: state.emailId }),
  restoreState: (key, storage) => Cookies.getJSON(key),
  saveState: (key, state, storage) =>
    Cookies.set(key, state, {
      expires: 1
    }
    )
}
)
export default new Vuex.Store({
  state: {
    isAuth: false,
    isError: false,
    errMsg: '',
    isOk: false,
    succMsg: '',
    isLoading: false,
    isProd: null,
    isAdmin: false,
    platform: null,
    emailId: '',
    selectedDateRadioOption: 'AllTime',
    selectedReportFromDate: '',
    selectedReportTillDate: '',
    selectedReportType: '',
    selectedEnrollmentStartDate: '',
    selectedEnrollmentEndDate: '',
    selectedDeploymentCode: '',
    selectedCurrentPatientStage: [],
    filterData: {},
    selectedincludeNotTakenDays: true,
    deploymentCodesServerBased: [],
    listOfDeploymentsAndTriggers: [],
    gitlabList: [],
    gitlabTicket: null,
    totalGitlabCount: 0,
    totalJiraLinked: 0,
    jiraList: [],
    totalJiraCount: 0,
    jiraTicket: null,
    scopes: null,
    generatedReport: null,
    gitlabSummaryList: [],
  },
  mutations: {
    AUTHENTICATE: (state, isAdmin = false) => {
      state.isAuth = true
      state.isAdmin = isAdmin
    },
    REMOVE_AUTHENTICATE: (state) => {
      state.isAuth = false
      state.isProd = null
    },
    ERROR_MSG: (state, ErrObj) => {
      state.isError = ErrObj.isError
      state.errMsg = ErrObj.errMsg
    },
    SUCCESS: (state, succObj) => {
      state.isOk = succObj.isOk
      state.succMsg = succObj.succMsg
    },
    SET_LOADING: (state, isLoading) => {
      state.isLoading = isLoading
    },
    SET_ENV: (state, isProd) => {
      state.isProd = isProd
    },
    CLEAR_MSG: (state) => {
      state.isError = false
      state.errMsg = ''
      state.isOk = false
      state.succMsg = ''
      state.isLoading = false
    },
    SET_DEPLOYMENT_NAME: (state, platform) => {
      state.platform = platform
    },
    SET_EMAIL_ID_VALUE: (state, emailId) => {
      state.emailId = emailId
    },
    SET_SELECTED_DATE_OPTION: (state, selectedDateRadioOption) => {
      if (selectedDateRadioOption === 'AllTime'){
        state.selectedEnrollmentStartDate = ''
        state.selectedEnrollmentEndDate = ''
      }
      state.selectedDateRadioOption = selectedDateRadioOption
    },
    SET_DEPLOYMENT_CODE: (state, deploymentCode) => {
      state.selectedDeploymentCode = deploymentCode
    },
    SET_REPORT_FROM_DATE: (state, reportFromDate) => {
      state.selectedReportFromDate = reportFromDate
    },
    SET_REPORT_TILL_DATE: (state, reportTillDate) => {
      state.selectedReportTillDate = reportTillDate
    },
    SET_INCLUDE_NOT_TAKEN_DAYS: (state, includeNotTakenDays) => {
      state.selectedincludeNotTakenDays = includeNotTakenDays
    },
    SET_ENROLLMENT_START_DATE: (state, enrollmentStartDate) => {
      state.selectedEnrollmentStartDate = enrollmentStartDate
    },
    SET_ENROLLMENT_END_DATE: (state, enrollmentEndDate) => {
      state.selectedEnrollmentEndDate = enrollmentEndDate
    },
    SET_REPORT_TYPE: (state, reportType) => {
      state.selectedReportType = reportType
    },
    SET_CURRENT_PATIENT_STAGE: (state, currentPatientStage) => {
      state.selectedCurrentPatientStage = currentPatientStage
    },
    SET_FILTERS: (state, filterData) => {
      state.filterData = filterData
    },
    CLEAR_API_FILTERS: (state) => {
      state.selectedDateRadioOption = 'AllTime'
      state.selectedReportFromDate = ''
      state.selectedReportTillDate = ''
      state.selectedincludeNotTakenDays = true
      state.selectedReportType = ''
      state.selectedEnrollmentStartDate = ''
      state.selectedEnrollmentEndDate = ''
      state.selectedDeploymentCode = ''
      state.selectedCurrentPatientStage = []
      state.filterData = {}
    },
    SET_SERVER_BASED_DEPLOYMENT_CODES_LIST: (state, deploymentCodes) => {
      for (const element of deploymentCodes) {
        state.deploymentCodesServerBased.push(element.code)
      }
    },
    SET_TRIGGER_LIST: (state, inputTriggerList) => {
      const groupedData = []
      inputTriggerList.forEach(item => {
        const existingGroup = groupedData.find(group => group.deploymentCode === item.deploymentCode && group.hierarchyId === item.hierarchyId)
        if (existingGroup) {
          existingGroup.triggers.push({
            id: item.id,
            triggerId: item.triggerId,
            cronTime: item.cronTime,
            eventName: item.eventName,
            functionName: item.functionName,
            mandatory: item.mandatory,
            entityTimeRelated: item.entityTimeRelated,
            isActive: item.active,
            defaultTemplateId: item.defaultTemplate ? item.defaultTemplate.templateId : null,
            templateIds: item.templates ? item.templates.map(template => template.templateId).join(',') : null,
            relatedTemplates: item.templates
          })
        } else {
          groupedData.push({
            deploymentCode: item.deploymentCode,
            hierarchyId: item.hierarchyId,
            triggers: [
              {
                id: item.id,
                triggerId: item.triggerId,
                cronTime: item.cronTime,
                eventName: item.eventName,
                functionName: item.functionName,
                mandatory: item.mandatory,
                entityTimeRelated: item.entityTimeRelated,
                isActive: item.active,
                defaultTemplateId: item.defaultTemplate ? item.defaultTemplate.templateId : null,
                templateIds: item.templates ? item.templates.map(template => template.templateId).join(',') : null,
                relatedTemplates: item.templates
              }
            ]
          })
        }
      })
      for (const element of groupedData) {
        state.listOfDeploymentsAndTriggers.push(element)
      }
    },
    SET_GITLAB_LIST: (state, list) => {
      state.gitlabList = list.map(item => {
        const urlParts = item.url.split('/');
        const number = urlParts[urlParts.length - 1]
        const linkedTicketsCount = Array.isArray(item.linkedTickets) ? item.linkedTickets.length : 0;
        return {
          ...item,
          number: number,
          linkedTicketsCount: linkedTicketsCount
        }
      })
    },
    SET_GITLAB_DETAILS: (state, item) => {
      const urlParts = item.url.split('/');
      const number = urlParts[urlParts.length - 1]
      const linkedTicketsCount = Array.isArray(item.linkedTickets) ? item.linkedTickets.length : 0;
        
      // Update state with the new fields
      state.gitlabTicket = {
            ...item,
            number: number,
            linkedTicketsCount: linkedTicketsCount
          }
    },
    SET_GITLAB_SUMMARY_LIST: (state, list) => {
      state.gitlabSummaryList = list
    },
    SET_GITLAB_COUNT: (state, count) => {
      state.totalGitlabCount = count
    },
    SET_TOTAL_LINKED_COUNT:(state, count) => {
      state.totalJiraLinked = count;
    },
    SET_JIRA_LIST: (state, list) => {
      state.jiraList = list.map(item => {
        const urlParts = item.url.split('/');
        const number = urlParts[urlParts.length - 1]
        const linkedTicketsCount = 0;
        const aiAssistant = 'Take Action'
        return {
          ...item,
          number: number,
          linkedTicketsCount: linkedTicketsCount,
          aiAssistant: aiAssistant
        }
      })
    },
    SET_JIRA_COUNT: (state, count) => {
      state.totalJiraCount = count
    },
    SET_JIRA_DETAILS: (state, item) => {
      state.jiraTicket = item
    },
    SET_SCOPES: (state, scopes) => {
      state.scopes = scopes
    },
    SET_GENERATED_REPORTS(state, report) {
      state.generatedReport = report;
    },
  },
  actions: {
    authenticate ({ commit }, isAdmin) {
      commit('AUTHENTICATE', isAdmin)
    },
    removeAuthenticate ({ commit }) {
      commit('REMOVE_AUTHENTICATE')
    },
    setEnvironment ({ commit }, isProd){
      commit('SET_ENV', isProd)
    },
    setErrorMessage ({ commit }, ErrObj){
      commit('ERROR_MSG', ErrObj)
    },
    success ({ commit }, succObj){
      commit('SUCCESS', succObj)
    },
    loading ({ commit }, loading){
      commit('SET_LOADING', loading)
    },
    clearMessage ({ commit }){
      commit('CLEAR_MSG')
    },
    setDeploymentName ({ commit }, platform){
      commit('SET_DEPLOYMENT_NAME', platform)
    },
    setEmailIdValue ({ commit }, emailId){
      commit('SET_EMAIL_ID_VALUE', emailId)
    },
    setSelectedDateOption ({ commit }, selectedDateRadioOption){
      commit('SET_SELECTED_DATE_OPTION', selectedDateRadioOption)
    },
    setSelectedDeploymentCode ({ commit }, selectedDeploymentCode){
      commit('SET_DEPLOYMENT_CODE', selectedDeploymentCode)
    },
    setSelectedReportFromDate ({ commit }, setSelectedReportFromDate){
      commit('SET_REPORT_FROM_DATE', setSelectedReportFromDate)
    },
    setSelectedReportTillDate ({ commit }, setSelectedReportTillDate){
      commit('SET_REPORT_TILL_DATE', setSelectedReportTillDate)
    },
    setSelectedIncludeNotTakenDays ({ commit }, setSelectedIncludeNotTakenDays){
      commit('SET_INCLUDE_NOT_TAKEN_DAYS', setSelectedIncludeNotTakenDays)
    },
    setSelectedEnrollmentStartDate ({ commit }, selectedEnrollmentStartDate){
      commit('SET_ENROLLMENT_START_DATE', selectedEnrollmentStartDate)
    },
    setSelectedEndollmentEndDate ({ commit }, setSelectedEnrollmentEndDate){
      commit('SET_ENROLLMENT_END_DATE', setSelectedEnrollmentEndDate)
    },
    setSelectedReportType ({ commit }, selectedReportType){
      commit('SET_REPORT_TYPE', selectedReportType)
    },
    setSelectedCurrentPatientStage ({ commit }, setSelectedCurrentPatientStage){
      commit('SET_CURRENT_PATIENT_STAGE', setSelectedCurrentPatientStage)
    },
    setFilters ({ commit }, filterData){
      commit('SET_FILTERS', filterData)
    },
    clearApiFilters ({ commit }){
      commit('CLEAR_API_FILTERS')
    },
    setScopes ({ commit }, scopes){
      commit('SET_SCOPES', scopes)
    },
    async setServerBasedDeploymentCodes ({ commit, state }, { data, path }){
      const usernameAndPassword = getUsernameAndPassword(state.isProd)
      const responseData = await ApiClient.get(usernameAndPassword.Username, usernameAndPassword.Password, data, path, 'registryENV')
      if (responseData.status === 200) {
        commit('SET_SERVER_BASED_DEPLOYMENT_CODES_LIST', responseData.data.data)
      } else {
        Vue.toasted.error(responseData.data.Error.Message, { duration: 2000 })
      }
    },
    async setListOfTriggersAndDeployments ({ commit }, { data, path }){
      try {
        const usernameAndPassword = getUsernameAndPassword(this.isProd)
        const responseData = await ApiClient.get(usernameAndPassword.Username, usernameAndPassword.Password, data, path, 'registryENV')
        this.state.listOfDeploymentsAndTriggers = []
        if (responseData.data.success) {
          commit('SET_TRIGGER_LIST', responseData.data.data)
        } else {
          Vue.toasted.error(data.data.error.Message, { duration: 2000 })
        }
      } catch (error) {
        Vue.toasted.error(data.data.Error.Message, { duration: 2000 })
      }
    },
    async genericPostCall ({ commit }, { username, password, data, path }){
      try {
        const responseData = await ApiClient.post(username, password, data, path)
        if (responseData.data.success) {
          return responseData
        } else {
          Vue.toasted.error(data.data.Error.Message, { duration: 2000 })
        }
      } catch (error){
        Vue.toasted.error(data.data.Error.Message, { duration: 2000 })
      }
    },
    async updateTriggerPostCall ({ commit }, { data, path }) {
      try {
        const usernameAndPassword = getUsernameAndPassword(this.isProd)
        const responseData = await ApiClient.put(usernameAndPassword.Username, usernameAndPassword.Password, data, path, 'registryENV')
        adminLogData("TriggerManagement", "Success: " + responseData.data.success, "Trigger Name: " + responseData.eventName + ", changed to " + data)
        DataForMatomoTracking("TriggerManagement", responseData.data.success, "Trigger Name: " + responseData.eventName + ", changed to " + data)
        if (responseData.data.success) {
          Vue.toasted.success('Changes Saved Successfully', { duration: 2000 })
        } else {
          Vue.toasted.error(responseData.data.error.message, { duration: 2000 })
        }
      } catch (error){
        Vue.toasted.error(error, { duration: 2000 })
      }
    },
    async cloneTriggerPostCall ({ commit }, { data, path }) {
      try {
        const usernameAndPassword = getUsernameAndPassword(this.isProd)
        const jsonUploadResponse = await ApiClient.post(usernameAndPassword.Username, usernameAndPassword.Password, data, path, 'registryENV')
        if (jsonUploadResponse){
          if (jsonUploadResponse.data.data === apiResponseValue.TriggersAdded){
            Vue.toasted.success('Trigger Cloned Successfully', { duration: 2000 })
          } else {
            Vue.toasted.error(jsonUploadResponse.data.data, { duration: 2000 })
          }
          adminLogData('TriggerManagement', 'StatusCode: ' + jsonUploadResponse.status, jsonUploadResponse.data.data)
          DataForMatomoTracking('TriggerManagement', jsonUploadResponse.status, jsonUploadResponse.data.data)
        }
      } catch (error){
        Vue.toasted.error(error, { duration: 2000 })
      }
    },
    async fetchGitlabList ({ commit }, { data, path }) {
      try {
        const responseData = await AdminApiClient.post(null, null, data, path, 'adminENV')
        if (responseData.data.success) {
          commit('SET_GITLAB_LIST', responseData.data.data.gitlabList)
          commit('SET_GITLAB_COUNT', responseData.data.data.totalCount)
          commit('SET_TOTAL_LINKED_COUNT', responseData.data.data.totalJiraLinked)
        } else {
          Vue.toasted.error(responseData.data.message, { duration: 2000 })
        }
      } catch (error){
        Vue.toasted.error(error, { duration: 2000 })
      }
    },
    async fetchGitlab ({ commit }, { data, path }) {
      try {
        const responseData = await AdminApiClient.get(null, null, null, path, 'adminENV')
        if (responseData.data.success) {
          commit('SET_GITLAB_DETAILS', responseData.data.data)
        } else {
          Vue.toasted.error(responseData.data.message, { duration: 2000 })
        }
      } catch (error){
        Vue.toasted.error(error, { duration: 2000 })
      }
    },
    async fetchGitlabSummaryList ({ commit }, { data, path }) {
      try {
        const responseData = await AdminApiClient.post(null, null, data, path, 'adminENV')
        if (responseData.data.success) {
          commit('SET_GITLAB_SUMMARY_LIST', responseData.data.data)
        } else {
          Vue.toasted.error(responseData.data.message, { duration: 2000 })
        }
      } catch (error){
        Vue.toasted.error(error, { duration: 2000 })
      }
    },
    async fetchJiraList ({ commit }, { data, path }) {
      try {
        const responseData = await AdminApiClient.post(null, null, data, path, 'adminENV')
        if (responseData.data.success) {
          commit('SET_JIRA_LIST', responseData.data.data.jiraList)
          commit('SET_JIRA_COUNT', responseData.data.data.totalCount)
        } else {
          Vue.toasted.error(responseData.data.message, { duration: 2000 })
        }
      } catch (error){
        Vue.toasted.error(error, { duration: 2000 })
      }
    },
    async fetchJira ({ commit }, { data, path }) {
      try {
        const responseData = await AdminApiClient.get(null, null, null, path, 'adminENV')
        if (responseData.data.success) {
          commit('SET_JIRA_DETAILS', responseData.data.data)
        } else {
          Vue.toasted.error(responseData.data.message, { duration: 2000 })
        }
      } catch (error){
        Vue.toasted.error(error, { duration: 2000 })
      }
    },
    async updateJira ({ commit }, { data, path }) {
      try {
        const responseData = await AdminApiClient.put(null, null, data, path, 'adminENV')
        if (responseData.data.success) {
          Vue.toasted.success(responseData.data.message, { duration: 2000 })
        } else {
          Vue.toasted.error(responseData.data.message, { duration: 2000 })
        }
        return responseData.data;
      } catch (error) {
        Vue.toasted.error(error, { duration: 2000 });
        return { success: false, message: error.message };
      }
    },
    async linkTickets ({ commit }, { data, path }) {
      try {
        const responseData = await AdminApiClient.post(null, null, data, path, 'adminENV')
        if (responseData.data.success) {
          Vue.toasted.success(responseData.data.message, { duration: 2000 })
        } else {
          Vue.toasted.error(responseData.data.message, { duration: 2000 })
        }
        return responseData.data
      } catch (error){
        Vue.toasted.error(error, { duration: 2000 })
        return { success: false, message: error.message };
      }
    },
    async addComment ({ commit }, { data, path }) {
      try {
        const responseData = await AdminApiClient.post(null, null, data, path, 'adminENV')
        if (responseData.data.success) {
          Vue.toasted.success('Comment Added!', { duration: 2000 })
        } else {
          Vue.toasted.error(responseData.data.message, { duration: 2000 })
        }
      } catch (error){
        Vue.toasted.error(error, { duration: 2000 })
      }
    },
    async createGitlabTicket ({ commit }, { data, path }) {
      try {
        const responseData = await AdminApiClient.post(null, null, data, path, 'adminENV')
        if (responseData.data.success) {
          Vue.toasted.success('Ticket Created!', { duration: 2000 })
        } else {
          Vue.toasted.error(responseData.data.message, { duration: 2000 })
        }
      } catch (error){
        Vue.toasted.error(error, { duration: 2000 })
      }
    },
    async getGeneratedReport({ commit }, { data, path }) {
      try {
        const response = await AdminApiClient.get(null, null, null, path, 'adminENV')
        if (response.data && response.data.success) {
          commit("SET_GENERATED_REPORTS", response.data.data);
        } else {
          Vue.toasted.error(response.data ? response.data.message : 'Failed to generate report', { duration: 2000 });
        }
      } catch (error) {
        const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An unexpected error occurred';
        Vue.toasted.error(errorMessage, { duration: 2000 });
        throw error;
      }
    }
  },
  getters: {
    checkAuthentication: (state) => {
      return state.isAuth
    }
  },
  plugins: [vuexCookie.plugin]
})
