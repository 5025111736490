export default {
  config: [
    {
      to: "/about",
      icon: "mdi-home",
      text: "Home"
    },
    {
      icon: "mdi-domain",
      text: "Nikshay",
      platform: "nikshay",
      subLinks: [
        {
          text: "TU Hierarchy Mapping Update",
          to: "/pfms",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "Create Logins",
          to: "/createloginsnikshay",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "Nikshay IMEI Addition",
          to: "/nikshayimeiaddition",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "Logins With New Hierarchies",
          to: "/loginswithnewhierarchies",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "DBT Beneficiary Migration",
          to: "/nikshayDbtBeneficiaryMigration",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          to: "/ticketManagement",
          icon: "mdi-domain",
          text: "Ticket Management",
        }
      ]
    },
    {
      icon: "mdi-domain",
      text: "Hub Saas",
      platform: "Hub",
      subLinks: [
        {
          text: "HUB IMEI Addition",
          to: "/hubimei",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "HUB Action Report",
          to: "/hubactionreport",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "Add New Deployment",
          to: "/addDeployment",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "HUB Dosing Report",
          to: "/hubdosingreport",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "HUB Create Logins",
          to: "/hubcreatelogins",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "HUB Create Hierarchies",
          to: "/hubcreatehierarchies",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "HUB Add Triggers",
          to: "/addTriggers",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "HUB Add Engagement",
          to: "/addEngagement",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        {
          text: "HUB Trigger Management",
          to: "/triggerManagement",
          icon: "mdi-arrow-up-bold-box-outline"
        },
        // {
        //   text: "HUB Create Super Users",
        //   to: "/createsuperusers",
        //   icon: "mdi-arrow-up-bold-box-outline"
        // },
        {
          text: "HUB Tasklist Management",
          to: "/tasklistManagement",
          icon: "mdi-arrow-up-bold-box-outline"
        }
      ]
    }
  ]
}
