<template>
  <div style="min-height: 15vh">
    <v-app-bar app color="#3f51b5" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title >Home</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-chip class="ma-2" v-bind:color="isProd ? '#f44336':'#ff9800'" text-color="white"> {{isProd ? "Prod":"Beta"}} </v-chip>
      <v-btn color="#3f51b5" @click="signout()"
        >Sign Out
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app v-bind:width="290" hide-overlay>
      <v-list>
        <div v-for="(link, i) in links" :key="i" >
          <v-list-item v-if="!link.subLinks" :to="link.to" class="v-list-item">
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="link.text" />
          </v-list-item>

          <v-list-group
            v-else
            :key="link.text"
            no-action
            :prepend-icon="link.icon"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title class="text-left pa-n5">{{ link.text }}</v-list-item-title>
            </template>

            <v-list-item
              v-on:click="drawer = false"
              v-for="sublink in link.subLinks"
              :to="sublink.to+'?platform='+link.platform"
              :key="sublink.text"
            >
              <v-list-item-icon>
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ sublink.text }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import router from "./../router/index"
import { mapState, mapActions } from 'vuex'
import { routeNames } from '../../utils/dataValidations'
import sideBarConfig from '../../utils/AppConfig/SidebarConfig'
import betaSidebarConfig from '../../utils/AppConfig/BetaSidebarConfig'
export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: false
  }),
  computed: {
    ...mapState([
      'isProd'
    ]),
    links () {
      return this.isProd ? sideBarConfig.config : betaSidebarConfig.config
    }
  },
  methods: {
    ...mapActions([
      'removeAuthenticate'
    ]),
    signout: function () {
      this.removeAuthenticate()
      router.push(routeNames.Home)
    }
  }
}
</script>
<style scoped>
.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
    padding-left: 20px;
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 10px;
}
</style>
