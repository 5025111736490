import Vue from 'vue'
import VueMatomo from 'vue-matomo'
import { objectKeysToLowerCase, eventCategoryName } from './dataValidations'
const store = require('../src/store/index')

export const initMatomo = (host, siteId) => {
  Vue.use(VueMatomo, {
    host: host,
    siteId: siteId,
    trackerFileName: 'matomo',
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: []
  })
}

export const adminLogData = (eventCategory, eventAction, data) => {
  window._paq.push(['setCustomVariable', 1, 'Email ID', store.default.state.emailId, 'visit'])
  window._paq.push(['trackEvent', eventCategory, eventAction, data])
}

export const initializeNikshayMatomo = () => {
  if (process.env.VUE_APP_ENV === "production" && store.default.state.isProd) {
    initMatomo(process.env.VUE_APP_NIKSHAY_MATOMO_URL, process.env.VUE_APP_NIKSHAY_ADMIN_MODULE_PROD_MATOMO_SITE_ID)
  } else {
    initMatomo(process.env.VUE_APP_NIKSHAY_MATOMO_URL, process.env.VUE_APP_NIKSHAY_ADMIN_MODULE_BETA_MATOMO_SITE_ID)
  }
}

export const initializePlatformMatomo = () => {
  if (process.env.VUE_APP_ENV === "production" && store.default.state.isProd) {
    initMatomo(process.env.VUE_APP_PLATFORM_MATOMO_URL, process.env.VUE_APP_PLATFORM_ADMIN_MODULE_PROD_MATOMO_SITE_ID)
  } else {
    initMatomo(process.env.VUE_APP_PLATFORM_MATOMO_URL, process.env.VUE_APP_PLATFORM_ADMIN_MODULE_BETA_MATOMO_SITE_ID)
  }
}

export const DataForMatomoTracking = (eventCategory, eventAction, data) => {
  let i
  switch (eventCategory){
    case eventCategoryName.TUHierarchyMappingUpdate:
      for (i = 0; i < data.length; i++){
        adminLogData(eventCategory, eventAction, "[" + "StateCode: " + data[i].StateCode + "," + "DistrictCode: " + data[i].DistrictCode + "," + "TBUCode: " + data[i].TBUCode + "," + "PfmsAgencyCode: " + data[i].PfmsAgencyCode + "]")
      }
      break
    case eventCategoryName.NikshayCreateLogins:
      for (i = 0; i < data.length; i++){
        var lowerCasedKey = objectKeysToLowerCase(data[i])
        adminLogData(eventCategory, eventAction, "[" + "Username: " + lowerCasedKey.username + "," + "HierarchyId: " + lowerCasedKey.hierarchyid + "]")
      }
      break
    case eventCategoryName.NikshayIMEIAddition:
      for (i = 0; i < data.length; i++){
        adminLogData(eventCategory, eventAction, "[" + "Imei: " + data[i].imei + "," + "Level_3_HierarchyId: " + data[i].level_3_HierarchyId + "]")
      }
      break
    case eventCategoryName.HUBIMEIAddition:
    case eventCategoryName.ASCENTIMEIAddition:
    case eventCategoryName.TANZANIAIMEIAddition:
      for (i = 0; i < data.length; i++){
        lowerCasedKey = objectKeysToLowerCase(data[i])
        adminLogData(eventCategory, eventAction, "[" + "DeploymentCode: " + lowerCasedKey.deploymentcode + "," + "Imei: " + lowerCasedKey.imei + "]")
      }
      break
    case eventCategoryName.LoginsWithNewHierarchies:
    case eventCategoryName.HUBCreateLogins:
    case eventCategoryName.ASCENTCreateLogins:
    case eventCategoryName.TANZANIACreateLogins:
    case eventCategoryName.HUBCreateSuperUsers:
    case eventCategoryName.ASCENTCreateSuperUsers:
    case eventCategoryName.TANZANIACreateSuperUsers:
      for (i = 0; i < data.length; i++){
        adminLogData(eventCategory, eventAction, "[" + "Username: " + data[i].username + "," + "AccessType: " + data[i].accessType + "]")
      }
      break
    case eventCategoryName.HUBAddDeployment:
    case eventCategoryName.ASCENTAddDeployment:
    case eventCategoryName.TanzaniaAddDeployment:
      for (i = 0; i < data.length; i++){
        adminLogData(eventCategory, eventAction, "[" + "Country Name: " + data[i].CountryName + "," + "Country Short Code: " + data[i].CountryShortName + "]")
      }
      break
    case eventCategoryName.HUBNewEngagement:
    case eventCategoryName.ASCENTNewEngagement:
    case eventCategoryName.TANZANIANewEngagement:
      for (i = 0; i < data.length; i++){
        adminLogData(eventCategory, eventAction, "[" + "Hierarchy Id: " + data[i].hierarchyId + "," + "DefaultTime: " + data[i].defaultTime + "]")
      }
      break
    case eventCategoryName.HUBTriggers:
    case eventCategoryName.ASCENTTriggers:
    case eventCategoryName.TanzaniaTriggers:
      for (i = 0; i < data.length; i++){
        adminLogData(eventCategory, eventAction, "[" + "Hierarchy Id: " + data[i].hierarchyId + "," + "Function Name: " + data[i].functionName + "]")
      }
      break
    case eventCategory.TriggerManagement:
      for (i = 0; i < data.length; i++){
        adminLogData(eventCategory, eventAction, data)
      }
      break
  }
}
