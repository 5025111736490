export const TypeOfPatientsAdded = (row) => {
  let result = true
  const list = ['IndiaTbPrivate', 'Default', 'API Plugins', 'NONE', 'IndiaTbPublic', 'IndiaTbPublic,IndiaTbPrivate']
  if (list.indexOf(row) === -1){
    result = false
  }
  return result
}
export const TypeOfStaffAllowedToAdd = (row) => {
  let result = true
  const list = ['IndiaTbPrivate', 'API Plugins', 'NONE', 'IndiaTbPublic', 'IndiaTbPublic,IndiaTbPrivate']
  if (list.indexOf(row) === -1){
    result = false
  }
  return result
}

export const HierarchyType = (row) => {
  let result = true
  const list = ['API', 'ART', 'COUNTRY', 'DISTRICT', 'HUB', 'PHI', 'PVTCHEM', 'PVTHUB', 'PVTLAB', 'STATE', 'TU']
  if (list.indexOf(row) === -1){
    result = false
  }
  return result
}

export const DrugRegimen = (row) => {
  let result = true
  const list = ['FDC', 'UNKNOWN', '3HP', 'INTERMITTENT']
  if (list.indexOf(row) === -1){
    result = false
  }
  return result
}

export const TrueorFalse = (row) => {
  let result = true
  const list = ['TRUE', 'FALSE']
  if (list.indexOf(row) === -1){
    result = false
  }
  return result
}

// Lower casing the keys to compare the values as the keys differ in Case.
export const objectKeysToLowerCase = (obj) => {
  const outputObj = {}
  for (const key in obj) {
    outputObj[key.toLowerCase()] = obj[key]
  }
  return outputObj
}

export const apiResponseType = {
  JSONUploadFailed: "JSON upload is failed.",
  SUCCESS: "Success",
  FAILED: "Failed",
  PARTIALSUCCESS: "Partial Success",
  HIERARCHYUPDATEFAIL: "Hierarchy Update Failed"
}

export const eventCategoryName = {
  NikshayDbtBeneficiaryMigration: 'DBT Beneficiary Migration',
  TUHierarchyMappingUpdate: 'TU Hierarchy Mapping Update',
  NikshayCreateLogins: 'Create Logins',
  NikshayIMEIAddition: 'Nikshay IMEI Addition',
  HUBIMEIAddition: 'Hub IMEI Addition',
  LoginsWithNewHierarchies: 'Logins With New Hierarchies',
  ASCENTIMEIAddition: 'Ascent IMEI Addition',
  TANZANIAIMEIAddition: 'Tanzania IMEI Addition',
  HUBCreateLogins: 'Hub Create Logins',
  ASCENTCreateLogins: 'Ascent Create Logins',
  TANZANIACreateLogins: 'Tanzania Create Logins',
  HUBCreateHierarchies: 'Hub Create Hierarchies',
  ASCENTCreateHierarchies: 'Ascent Create Hierarchies',
  TANZANIACreateHierarchies: 'Tanzania Create Hierarchies',
  HUBAddDeployment: 'Hub Add New Deployment',
  ASCENTAddDeployment: 'Ascent Add New Deployment',
  TanzaniaAddDeployment: 'Tanzania Add New Deployment',
  HUBNewEngagement: 'Hub Add Engagement',
  ASCENTNewEngagement: 'Ascent Add Engagement',
  TANZANIANewEngagement: 'Tanzania Add Engagement',
  HUBTriggers: 'Hub Add Triggers',
  ASCENTTriggers: 'Ascent Add Triggers',
  TanzaniaTriggers: 'Tanzania Add Triggers',
  TriggerManagement: 'TriggerManagement',
  HUBCreateSuperUsers: 'Hub Create Super Users',
  ASCENTCreateSuperUsers: 'Ascent Create Super Users',
  TANZANIACreateSuperUsers: 'Tanzania Create Super Users',
  TasklistMasterList: 'Tasklist Master List'
}

export const apiResponseValue = {
  LoginsAreCreated: 'Logins are created.',
  CsvFileNotReceivedOrIsInvalid: 'Csv file not received or is invalid',
  InvalidAccess: 'Invalid Access',
  UserIsNotAuthenticated: 'User is not Authenticated.',
  IMEIsAreAdded: 'IMEIs are added.',
  Success: 'Success',
  JsonNotReceived: 'Json not received',
  DeploymentCreated: 'Deployments Created',
  EngagementsAdded: 'Engagements Added to the Database',
  TriggersAdded: 'Triggers Added to the Database'
}

export const routeNames = {
  Home: '/',
  About: '/about',
  Welcome: '/welcome'
}

export const platformTypes = {
  Nikshaybeta: 'nikshay-beta',
  Nikshayprod: 'nikshay-prod',
  Platformbeta: 'Hub-beta',
  Platformprod: 'Hub-prod',
  Ascentbeta: 'Ascent-beta',
  Ascentprod: 'Ascent-prod',
  Tanzaniabeta: 'Tanzania-beta',
  Tanzaniaprod: 'Tanzania-prod'
}

export const actionReportTypes = ['IvrLogs', 'PatientLogs', 'ALL']

export const patientStageTypes = ['On Treatment', 'Off Treatment', 'ALL']

export const getCurrentPatientStageMapping = (stage) => {
  let result = []
  if (stage === 'On Treatment'){
    result = ['ON_TREATMENT']
  } else if (stage === 'Off Treatment'){
    result = ['OFF_TREATMENT']
  } else if (stage === 'ALL') {
    result = ['ON_TREATMENT', 'OFF_TREATMENT']
  }
  return result
}

export const betaUsernameAndPassword = {
  Username: 'ADMIN-MODULE01',
  Password: 'Test@123'
}

export const prodUsernameAndPassword = {
  Username: 'ADMIN-MODULE01',
  Password: 'ADMIN-MODULE01'
}

export const getUsernameAndPassword = (isEnvironmentProduction) => {
  let result = {}
  if (isEnvironmentProduction) {
    result = prodUsernameAndPassword
  } else {
    result = betaUsernameAndPassword
  }
  return result
}
