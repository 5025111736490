<template>
  <div>
    <v-btn color="indigo" tile
    v-google-signin-button="clientId" >Sign In using Everwell Email Account</v-btn>
  </div>
</template>

<script>
import mails from '../../adminMails.json'
import { mapState, mapActions } from 'vuex'
import router from './../router/index'
import { routeNames } from '../../utils/dataValidations'
export default {
  props: {
    msg: String
  },
  data: () => ({
    clientId: process.env.VUE_APP_ClientId,
    scope: 'email profile openid'
  }),
  methods: {
    ...mapActions([
      'authenticate',
      'setEmailIdValue',
      'setScopes'
    ]),
    OnGoogleAuthSuccess (Token, googleUser, scopes) {
      const profile = googleUser.getBasicProfile()
      const usermail = profile.getEmail()
      let isAdmin = false
      mails.forEach(x => {
        if (x.Admin === usermail){
          isAdmin = true
        }
      })
      this.setScopes(scopes)
      this.authenticate(isAdmin)
      this.setEmailIdValue(usermail)
      router.push(routeNames.Welcome)
    },
    OnGoogleAuthFail (error) {
      console.log(error)
    }
  }
}
</script>
<style scoped>
</style>
