import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/pfms',
    name: 'Pfms',
    component: () => import('../views/TUHierarchyMappingUpdate.vue')
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('../components/Welcome.vue')
  },
  {
    path: '/hubimei',
    name: 'HubImei',
    component: () => import('../views/HubImeiAddition.vue')
  },
  {
    path: '/createloginsnikshay',
    name: 'CreateLoginsNikhsay',
    component: () => import('../views/CreateLoginsNikshay.vue')
  },
  {
    path: '/nikshayimeiaddition',
    name: 'nikshayimeiaddition',
    component: () => import('../views/NikshayImeiAddition')
  },
  {
    path: '/loginswithnewhierarchies',
    name: 'loginswithnewhierarchies',
    component: () => import('../views/LoginsWithNewHierarchies')
  },
  {
    path: '/hubactionreport',
    name: 'hubactionreport',
    component: () => import('../views/HubActionReport.vue')
  },
  {
    path: '/addDeployment',
    name: '/addDeployment',
    component: () => import('../views/AddNewDeployment')
  },
  {
    path: '/hubdosingreport',
    name: 'hubdosingreport',
    component: () => import('../views/HubDosingReport.vue')
  },
  {
    path: '/hubcreatelogins',
    name: 'hubcreatelogins',
    component: () => import('../views/HUBCreateLogins.vue')
  },
  {
    path: '/hubcreatehierarchies',
    name: 'hubcreatehierarchies',
    component: () => import('../views/HUBCreateHierarchies.vue')
  },
  {
    path: '/addEngagement',
    name: 'addEngagement',
    component: () => import('../views/AddEngagement.vue')
  },
  {
    path: '/addTriggers',
    name: 'addTriggers',
    component: () => import('../views/AddTriggers.vue'),
    query: { platform: '' }
  },
  {
    path: '/triggerManagement',
    name: 'triggerManagement',
    component: () => import('../views/TriggerManagement.vue'),
    query: { platform: '' }
  },
  {
    path: '/triggerManagement/EditTrigger',
    name: 'editTrigger',
    component: () => import('../views/EditTriggers.vue'),
    props: true,
    query: { platform: '' }
  },
  // {
  //   path: '/createsuperusers', //superuser is not required in registry backend.
  //   name: 'createsuperusers',
  //   component: () => import('../views/HUBCreateSuperUsers.vue')
  // },
  {
    path: '/tasklistManagement',
    name: 'tasklistManagement',
    component: () => import('../views/TaskListManagement.vue')
  },
  {
    path: '/nikshayDbtBeneficiaryMigration',
    name: 'nikshayDbtBeneficiaryMigration',
    component: () => import('../views/NikshayDbtBeneficiaryMigration')
  },
  {
    path: '/ticketManagement',
    name: 'ticketManagement',
    component: () => import('../views/TicketManagement.vue')
  },
  {
    path: '/ticketManagement/jira/:id',
    name: 'eachTicketDetail',
    component: () => import('../views/TicketDetail.vue')
  },
  {
    path: '/ticketManagement/gitlab/:id',
    name: 'linkedTickets',
    component: () => import('../views/LinkedTickets.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
