import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import GoogleSignInButton from './googleSignIn/index'
import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import Navbar from './components/Navbar.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

Vue.config.productionTip = false

// Add the Font Awesome icons to the library
library.add(faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight);

// Register the FontAwesomeIcon component globally
Vue.component('font-awesome-icon', FontAwesomeIcon);


Sentry.init({
  environment: process.env.VUE_APP_ENV,
  dsn: process.env.VUE_APP_DSN,
  integrations: [new VueIntegration({ Vue, attachProps: true })],
  release: process.env.PACKAGE_VERSION// "test"
})

new Vue({
  data: {
    state: {
      isAuth: false
    }
  },
  Navbar,
  GoogleSignInButton,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
