<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" class="responsive"><br><br><br><br>
    <LoginCheck/>
  </div>
</template>

<script>
import LoginCheck from '../components/LoginCheck'
import router from './../router/index'
import { mapState } from 'vuex'
import { routeNames } from '../../utils/dataValidations'
export default {
  name: 'Home',
  components: {
    LoginCheck
  },
  computed: {
    ...mapState([
      'isAuth'
    ])
  },
  mounted: function () {
    if (this.isAuth) {
      router.push(routeNames.About)
    }
  }
}
</script>
<style scoped>
.responsive {
  max-width: 100%;
}
</style>
